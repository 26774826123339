import React, { useRef } from "react";
import Logo from "../Logo";
import styles from "./index.module.css";
import useHeader from "./useHeader";
import useNavItems from "./useNavItems";

const NavItems = ({ sectionRefs, headerRef }) => {
  const { handleButtonClick, selectedButton } = useNavItems(
    sectionRefs,
    headerRef
  );
  return (
    <div className={`d-flex center-align-item ${styles.item_gap}`}>
      <div className={styles.nav_item}>
        <div
          className={styles.nav_item_name}
          onClick={() => handleButtonClick(0)}
        >
          Home
        </div>
        <div
          className={`w-100 ${
            selectedButton === 0
              ? styles.active + " " + styles.active_element
              : styles.active
          }`}
        ></div>
      </div>
      <div className={styles.nav_item}>
        <div
          className={styles.nav_item_name}
          onClick={() => handleButtonClick(1)}
        >
          What You Learn?
        </div>
        <div
          className={`w-100 ${
            selectedButton === 1
              ? styles.active + " " + styles.active_element
              : styles.active
          }`}
        ></div>
      </div>
      <div className={styles.nav_item}>
        <div
          className={styles.nav_item_name}
          onClick={() => handleButtonClick(2)}
        >
          Testimonial
        </div>
        <div
          className={`w-100 ${
            selectedButton === 2
              ? styles.active + " " + styles.active_element
              : styles.active
          }`}
        ></div>
      </div>
      <div
        className={`d-flex center-align-item jstify-space-around ${styles.contact_us}`}
        onClick={() => handleButtonClick(3)}
      >
        Contact Us
      </div>
    </div>
  );
};

export default function DesktopHeader({ sectionRefs, trigger }) {
  const headerRef = useRef(null);
  useHeader(headerRef, trigger);
  return (
    <div
      ref={headerRef}
      className={`d-flex center-align-item jstify-space-between w-100 container_fluid ${styles.header}`}
    >
      <Logo />
      <NavItems sectionRefs={sectionRefs} headerRef={headerRef} />
    </div>
  );
}
