import { useEffect, useRef } from "react";
import "./App.css";
import ClassGallery from "./components/ClassGallery";
import ClassInfo from "./components/ClassInfo";
import ContactUs from "./components/ContactUs";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Home from "./components/Home";
import LearningObjectives from "./components/LearningOutcomes";
import Loacation from "./components/Location";
import Testimonial from "./components/Testimonial";
import "./custom-swiper.css";

function App() {
  const sectionRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];
  const triggerRef = useRef(null);
  useEffect(() => {
    // Set scroll position to top (0, 0) on page refresh
    window.scrollTo(0, 0);
  }, []); // Empty dependency array ensures this runs once when the component mounts
  return (
    <div className="homepage_conatiner">
      <Header sectionRefs={sectionRefs} trigger={triggerRef} />
      <div ref={triggerRef}> </div>
      <div ref={sectionRefs[0]}>
        <Home />
        <ClassInfo />
        <ClassGallery />
      </div>
      <div ref={sectionRefs[1]}>
        <LearningObjectives />
      </div>
      <div ref={sectionRefs[2]}>
        <Testimonial />
      </div>
      <div ref={sectionRefs[3]}>
        <ContactUs />
        <Loacation />
      </div>
      <Footer />
    </div>
  );
}

export default App;
