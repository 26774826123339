import React from "react";
import styles from "./index.module.css";
import UXFoundation from "../../assests/images/UXFoundation.png";
import UXDesign from "../../assests/images/UXDesign.png";
import UserResearch from "../../assests/images/UserResearch.png";
import UXStrategy from "../../assests/images/UXStrategy.png";
import UXCaseStudy from "../../assests/images/UXCaseStudy.png";
import VisualDesign from "../../assests/images/VisualDesign.png";
import UsabilityTesting from "../../assests/images/UsabilityTesting.png";
import Accessibility from "../../assests/images/Accessibility.png";
import HeuristicEvaluation from "../../assests/images/HeuristicEvaluation.png";
const KeyLearnings = () => {
  return (
    <div
      className={`d-flex center-align-item jstify-space-around ${styles.key_learnings_container}`}
    >
      <div className={styles.key_learnings_name}>What You Learn?</div>
      <div className={styles.key_learnings_desc}>
        Start your journey towards becoming a UX professional. Our program will
        help you to kickstart your journey and provide necessary tools and the
        knowledge to achieve your goals in the field of UX.
      </div>
    </div>
  );
};

const LearningObjective = ({ item }) => {
  return (
    <div className={styles.learning_objective_card}>
      <img src={item.imgURL} alt="Learing point" className={styles.card_image} />
      <div className={styles.card_name}>{item.cardName}</div>
      <div className={styles.card_desc}>{item.cardDesc}</div>
    </div>
  );
};

function LearningObjectives() {
  const learningObjectives = [
    {
      id: 1,
      cardName: "UX Foundation",
      cardDesc:
        "Gain a strong foundation in UX design by learning the importance of user experience in creating effective and user-friendly products. Enhance your understanding of UX principles and build a solid base for your skills as you dive into the fundamentals. This course will equip you with the knowledge needed to design with the user in mind, ensuring a seamless and enjoyable experience.",
      imgURL: UXFoundation,
    },
    {
      id: 2,
      cardName: "Fundamentals of UX Design",
      cardDesc:
        "Mastering the fundamentals of UX design is crucial for creating user-centered designs. Developing essential skills helps in crafting intuitive and engaging experiences that resonate with users. By learning the basics, you can understand key principles that enhance your grasp of user behavior and decision-making, leading to more effective and satisfying design solutions.",
      imgURL: UXDesign,
    },
    {
      id: 3,
      cardName: "User Research",
      cardDesc:
        "Understanding User Research methodology, significance of directly obtaining user insights. Key techniques include interviews, surveys, and usability testing. Effective user research involves planning,  data collection, and thorough analysis. Applying these findings to design decisions ensures user-centered and effective solutions. Mastering these skills and integrating design thinking leads to successful UX design projects.",
      imgURL: UserResearch,
    },
    {
      id: 4,
      cardName: "UX Strategy",
      cardDesc:
        "UX strategy is a plan for creating a great user experience. It involves understanding user needs, designing solutions, and measuring success. Learning UX strategy requires studying methods and techniques for creating successful experiences. This knowledge can be applied to improve products, services, and overall user satisfaction.",
      imgURL: UXStrategy,
    },
    {
      id: 5,
      cardName: "UX Case Study  Development",
      cardDesc:
        "UX case study, start by learning the process of crafting one, which involves outlining the project’s objectives, research, design solutions, and results. Understand the key components required, such as user research, personas, wireframes, prototypes, and usability testing. Mastering these elements will enable you to effectively present your case study, showcasing your design thinking and problem-solving skills in a compelling manner.",
      imgURL: UXCaseStudy,
    },
    {
      id: 6,
      cardName: "Visual Design  (Figma Tool)",
      cardDesc:
        "Discover the fundamentals of visual design and enhance your expertise in composition by studying key principles and elements of design. Hone your ability to create visually captivating work and learn to effectively convey ideas through visual media. Gain a comprehensive understanding of the art of visual communication to elevate your design skills.",
      imgURL: VisualDesign,
    },
    {
      id: 7,
      cardName: "Usability Testing",
      cardDesc:
        "Usability testing is a method used in design and development to assess how easy a product or service is to use by having real users perform tasks. This process helps identify issues that may affect the user experience, allowing for improvements to be made based on user feedback. The ultimate goal is to ensure that the product or service is user-friendly and meets the needs of its audience.",
      imgURL: UsabilityTesting,
    },
    {
      id: 8,
      cardName: "Accessibility",
      cardDesc:
        "Understanding accessibility involves recognizing its significance across different contexts, ensuring inclusivity and equality for all users. Familiarizing with accessibility principles helps identify barriers that individuals with disabilities may face. Exploring various assistive technologies reveals how they support accessibility. Reflecting on how accessibility impacts user experience and design considerations is crucial for creating effective, inclusive solutions.",
      imgURL: Accessibility,
    },
    {
      id: 9,
      cardName: "Heuristic Evaluation",
      cardDesc:
        "Heuristic Evaluation focuses on assessing a product or system using established design principles. Conducted by experts, this method aims to uncover potential usability issues and recommend improvements. By evaluating based on heuristics, it offers valuable insights into user experience and helps enhance the overall design of a product.",
      imgURL: HeuristicEvaluation,
    },
  ];
  return (
    <div className={`container_fluid ${styles.learning_objectives_container}`}>
      <KeyLearnings />
      <div className={styles.learning_objective_card_container}>
        {learningObjectives.map((learningObjective, index) => (
          <div
            key={learningObjective.id}
            className={`${styles.width_card} ${
              index % 2 === 0 ? styles.width_card_flex_end : ""
            }`}
          >
            <LearningObjective
              key={learningObjective.id}
              item={learningObjective}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default LearningObjectives;
