import React from "react";
import styles from "./index.module.css";
import HarishMarsale from "../../assests/images/HarishMarsale.png";
import AshutoshPotdar from "../../assests/images/AshutoshPotdar.png";
import BhushanPanchal from "../../assests/images/BhushanPanchal.png";
import User from "../../assests/images/User.jpeg";
import Dot from "../../assests/images/Dot.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css"; // Core Swiper styles
import "swiper/css/navigation"; // Import Navigation styles
import "swiper/css/pagination"; // Import Pagination styles
import { useMediaQuery } from "react-responsive";

const TestimonialCard = ({ item }) => {
  return (
    <div className={styles.testimonial_card_container}>
      <div
        className={`d-flex center-align-item ${styles.testimonial_card__header}`}
      >
        <img
          src={item.URL}
          alt={item.name}
          className={styles.testimonial_card__header_image}
        />
        <div
          className={`w-100 d-flex center-align-item jstify-space-between ${styles.testimonial_card_name_container}`}
        >
          <div className={styles.testimonial_card_name_designation}>
            <div className={styles.testimonial_card_name}>{item.name}</div>
            <div className={styles.testimonial_card_designation}>
              {item.designation}
            </div>
          </div>
          <div className={styles.testimonial_card_quote}>“</div>
        </div>
      </div>
      <div className={styles.testimonial_card_desc}>{item.desc}</div>
    </div>
  );
};

function Testimonial() {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const testimonials = [
    {
      id: 1,
      URL: HarishMarsale,
      name: "Harish Marsale",
      designation: "Accenture, India",
      desc: "I would like to express my gratitude to Santosh Sir and Girish for their fantastic sessions; they were both informative and engaging, and I gained a lot from them. The course material is thorough and well-organized, effectively covering key topics like user research, wireframing, and prototyping. However, including more case studies could provide valuable practical insights and further enrich the learning experience",
    },
    {
      id: 2,
      URL: BhushanPanchal,
      name: "Bhushan Panchal",
      designation: "Visual Designer, India",
      desc: "The 4-month UX course exceeded my expectations! The curriculum was well-structured, and the instructors were knowledgeable and supportive. I gained hands-on experience with industry-standard tools and developed a solid understanding of user-centered design principles. This course has prepared me for a successful career in UX design",
    },
    {
      id: 3,
      URL: AshutoshPotdar,
      name: "Ashutosh Potdar",
      designation: "zensar technologies, India",
      desc: "The hands-on experience truly prepared me for a career in UI/UX. Real-world projects sharpened my skills in wireframing and user research. The incredible mentorship from my teachers was both inspiring and encouraging! The growth opportunities are limitless. From user interviews to high-fidelity prototypes, I’ve honed my UI/UX skills with supportive teachers who genuinely care about our success. It’s been a life-changing journey!”",
    },
    {
      id: 4,
      URL: User,
      name: "Vijay Shelke",
      designation: "Atos, India",
      desc: "The course was very engaging and I enjoyed learning about UX concepts and design. I already had pass knowledge about UX but this course helped me to become a stronger UX designer. The teachers were very good at explaining all the different stages and were very approachable. I would definitely recommend this Course to anyone considering furthering their knowledge and practical skills in this field.",
    },
  ];

  return (
    <div className={styles.testimonial_container}>
      <img
        src={Dot}
        alt="Dot"
        className={styles.testimonial_container_dot_image}
      />
      <div className={styles.testimonial}>Testimonial</div>
      <div className={styles.testimonial_stories}>
        <Swiper
          modules={[Navigation, Pagination]} // Add the Navigation module
          navigation // Enable the navigation (arrows)
          slidesPerView={isMobile ? 1.2 : 3.2} // Show 1 full slide and part of the next
          spaceBetween={20} // Space between slides
          loop={true}
          pagination={{ clickable: true }} // Enable the dots (pagination)
        >
          {testimonials.map((item) => (
            <SwiperSlide key={item.id}>
              <TestimonialCard item={item} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <img
        src={Dot}
        alt="Dot"
        className={styles.testimonial_container_dot_image1}
      />
    </div>
  );
}

export default Testimonial;
