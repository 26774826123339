import { useEffect } from "react";
import styles from "./index.module.css";

function useHeader(headerRef, trigger, isMobile) {
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Check if the 'trigger' element is intersecting
        if (!isMobile) {
          if (!entry.isIntersecting) {
            if (!headerRef?.current?.classList?.contains(styles.scrolled)) {
              headerRef.current.classList.add(styles.scrolled); // Add class when trigger is out of view
            }
          } else if (headerRef?.current?.classList?.contains(styles.scrolled)) {
            headerRef.current.classList.remove(styles.scrolled); // Add class when trigger is out of view
          }
        }
        if (isMobile) {
          if (!entry.isIntersecting) {
            if (
              !headerRef?.current?.classList?.contains(styles.mobile_scrolled)
            ) {
              headerRef.current.classList.add(styles.mobile_scrolled); // Add class when trigger is out of view
            }
          } else if (
            headerRef?.current?.classList?.contains(styles.mobile_scrolled)
          ) {
            headerRef.current.classList.remove(styles.mobile_scrolled); // Add class when trigger is out of view
          }
        }
      },
      {
        root: null, // The viewport
        threshold: 0, // Trigger when the trigger element is fully out of view
      }
    );

    // Observe the trigger element
    if (trigger?.current) {
      observer.observe(trigger.current);
    }
  }, [trigger]);
}

export default useHeader;
