import React from "react";
import styles from "./index.module.css";
import EmailIcon from "../../assests/icons/EmailIcon.png";
import Callcon from "../../assests/icons/CallIcon.png";
import LoacationIcon from "../../assests/icons/LocatinIcon.png";

const CardDetail = ({ src, item }) => {
  return (
    <div
      className={`d-flex jstify-space-around ${styles.card_email_container}`}
    >
      <div className={`d-flex start-align-item`}>
        <img className={styles.card_iamge} src={src} alt="Email" />
        <div className={styles.card_email}>{item}</div>
      </div>
    </div>
  );
};

const Card = ({ children, name }) => {
  return (
    <div className={styles.location_card_container}>
      <div className={`d-flex jstify-space-around ${styles.card_title}`}>
        {name}
      </div>
      {children}
    </div>
  );
};

function Loacation() {
  return (
    <div className={styles.location_container}>
      <Card name="Get in Touch">
        <CardDetail src={EmailIcon} item="www.urdtraining.org" />
        <CardDetail src={Callcon} item="+91 8591544392" />
      </Card>
      <Card name="Location">
        <CardDetail
          src={LoacationIcon}
          item="Office 4, A-wing, Shivmandiri Complex, Palm Beach Road, Sanpada Sector 13, Navi Mumbai - 400705.  Maharashtra, India."
        />
      </Card>
    </div>
  );
}

export default Loacation;
