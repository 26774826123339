import React, { useRef } from "react";
import emailjs from "emailjs-com";
import styles from "./index.module.css";
import contactUs from "../../assests/images/contact_us.png";

function ContactUs() {
  const formDataRef = useRef(null);

  const handleFormData = (e) => {
    e.preventDefault();
    const formData = new FormData(formDataRef.current);
    const name = formData.get("name");
    const email = formData.get("email");
    const message = formData.get("message");
    const mobileNo = formData.get("mobileNo");

    console.log(name, email, message);

    const templateParams = {
      to_name: "URD",
      from_name: name,
      subject: `Hello from ${name}`,
      message: message,
      mobile_no: mobileNo,
      email: email,
    };

    const serviceId = "service_9ao7yll";
    const templateId = "template_144v797";
    const key = "_1tzxXk6v6ua5uMZr";

    // tfod xobk lscz fxoc

    // steps:
    // 1. Go to Gmail Account
    // 2. Go to App Paasword
    // 3. Add new Password
    // 4. Go to Email JS
    // 5. Add Gmail Services
    // 6. Add Template

    // ServiceID, TemplateID, Tempalate Data, Local Key(Email Js Account)
    if (name && email) {
      emailjs.send(serviceId, templateId, templateParams, key).then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
          formDataRef.current.reset();
        },
        (err) => {
          console.log("FAILED...", err);
        }
      );
    }
  };
  return (
    <div className={`${styles.contact_us_container}`}>
      <img
        src={contactUs}
        alt="Contact US"
        className={styles.contact_us_image}
      />
      <div className={styles.contact_details}>
        <div className={styles.contact_us_quetions}>
          <div
            className={`d-flex jstify-space-around ${styles.contact_us_title}`}
          >
            Contact Us
          </div>
          <div
            className={`d-flex jstify-space-around ${styles.contact_us_desc}`}
          >
            Have questions or need assistance? Reach out to us.
          </div>
        </div>
        <form
          ref={formDataRef}
          className={styles.contact_form_container}
          onSubmit={(e) => handleFormData(e)}
        >
          <input
            name="name"
            type="text"
            className={`${styles.name_email_input} ${styles.contact_input}`}
            placeholder="Your full name"
          />
          <input
            name="mobileNo"
            type="text"
            className={`${styles.name_email_input} ${styles.contact_input}`}
            placeholder="Mobile Number"
          />
          <input
            name="email"
            type="text"
            className={`${styles.name_email_input} ${styles.contact_input}`}
            placeholder="Your email address"
          />
          <textarea
            name="message"
            type="text"
            className={`${styles.contact_request} ${styles.contact_input}`}
            placeholder="Write a note about your request"
          />
          <button className={styles.contact_button}>Send</button>
        </form>
      </div>
    </div>
  );
}

export default ContactUs;
