import React from "react";
import DesktopHeader from "./DesktopHeader";
import MobileHeader from "./MobileHeader";
import { useMediaQuery } from "react-responsive";

function Header({ sectionRefs, trigger }) {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  return isMobile ? (
    <MobileHeader sectionRefs={sectionRefs} trigger={trigger} />
  ) : (
    <DesktopHeader sectionRefs={sectionRefs} trigger={trigger} />
  );
}

export default Header;
