import React from "react";
import styles from "./index.module.css";
import Image1 from "../../assests/images/Image1.png";
import Image2 from "../../assests/images/Image2.png";
import Image3 from "../../assests/images/Image3.png";
import Image4 from "../../assests/images/Image4.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css"; // Core Swiper styles
import "swiper/css/navigation"; // Import Navigation styles
import "swiper/css/pagination"; // Import Pagination styles
import { useMediaQuery } from "react-responsive";

const Image = ({ src }) => {
  return <img src={src} alt={src} className={styles.class_gallery_images} />;
};

function ClassGallery() {
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const images = [
    {
      id: 1,
      URL: Image1,
    },
    {
      id: 2,
      URL: Image2,
    },
    {
      id: 3,
      URL: Image3,
    },
    {
      id: 4,
      URL: Image4,
    },
    {
      id: 5,
      URL: Image3,
    },
    {
      id: 6,
      URL: Image2,
    },
  ];
  return (
    <div className={styles.class_gallery_container}>
      <Swiper
        modules={[Navigation, Pagination]} // Add the Navigation module
        navigation // Enable the navigation (arrows)
        slidesPerView={isMobile ? 1.4 : 3.5} // Show 1 full slide and part of the next
        spaceBetween={20} // Space between slides
        loop={true}
        pagination={{ clickable: true }} // Enable the dots (pagination)
      >
        {images.map((item) => (
          <SwiperSlide key={item.id}>
            <Image src={item.URL} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

export default ClassGallery;
