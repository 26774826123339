import React from "react";
import styles from "./index.module.css";
import Logo from "../Logo";
import TwiterIcon from "../../assests/icons/TwiterIcon.png";
import InstaIcon from "../../assests/icons/InstaIcon.png";
import FacebookIcon from "../../assests/icons/FacebookIcon.png";
import LinkedInIcon from "../../assests/icons/LinkedInIcon.png";

const Links = ({ links }) => {
  return (
    <div className={styles.links_container}>
      {links.map((link, index) => {
        return (
          <div key={index} className={styles.link_title}>
            {link.name}
          </div>
        );
      })}
    </div>
  );
};

function Footer() {
  const year = new Date().getFullYear();
  const firstLinks = [
    {
      id: 1,
      name: "Home",
    },
    {
      id: 2,
      name: "About",
    },
    {
      id: 3,
      name: "Sign Up",
    },
  ];
  const secondLinks = [
    {
      id: 1,
      name: "Services",
    },
    {
      id: 2,
      name: "Contact",
    },
    {
      id: 3,
      name: "Privacy Policy",
    },
  ];
  return (
    <div className={styles.footer_container}>
      <div className={styles.footer_container_main}>
        <div className={styles.footer_container1}>
          <Logo
            className={styles.mobile_logo}
            mainClass={styles.logo_main_class}
          />
          <div className={styles.links_container_main}>
            <Links links={firstLinks} />
            <Links links={secondLinks} />
          </div>
        </div>
        <div className={styles.footer_cws_container}>
          <div className={styles.footer_cws}>Connect With Us</div>
          <div className={styles.footer_handler}>
            <img src={TwiterIcon} alt="Twiter" />
            <img src={InstaIcon} alt="Insta" />
            <img src={FacebookIcon} alt="Facebook" />
            <img src={LinkedInIcon} alt="LinkedIn" />
          </div>
        </div>
      </div>
      <div className={`${styles.footer_cws} ${styles.footer_copyright}`}>
        {`Copyright © ${year} URD, All right reserved.`}
      </div>
    </div>
  );
}

export default Footer;
