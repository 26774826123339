import React from "react";
import styles from "./index.module.css";

import LiveClassesIcon from "../../assests/icons/LiveClassesIcon.png";
import OfflineWorkshopIcon from "../../assests/icons/OfflineWorkshopIcon.png";
import DurationIcon from "../../assests/icons/DurationIcon.png";
import MentorshipIcon from "../../assests/icons/MentorshipIcon.png";

const ClassFeature = ({ item }) => {
  return (
    <div
      className={`d-flex center-align-item jstify-space-between ${styles.class_feature_container}`}
    >
      <img
        src={item.URL}
        alt="Live Classes"
        className={styles.class_feature_logo}
      />
      <div className={styles.feature_name}>{item.featureName}</div>
      <div className={styles.feature_duration}>{item.duration}</div>
    </div>
  );
};

function ClassInfo() {
  const classFeature = [
    {
      id: 1,
      featureName: "Live Classes",
      duration: "Every SAT & SUN",
      URL: LiveClassesIcon,
    },
    {
      id: 2,
      featureName: "Offline Workshop",
      duration: "UX Activity",
      URL: OfflineWorkshopIcon,
    },
    {
      id: 3,
      featureName: "Duration",
      duration: "4 Months",
      URL: DurationIcon,
    },
    {
      id: 4,
      featureName: "Mentorship",
      duration: "Certified UX Professional",
      URL: MentorshipIcon,
    },
  ];

  return (
    <div
      className={`d-flex center-align-item jstify-space-between ${styles.class_info_container}`}
    >
      {classFeature.map((item) => (
        <ClassFeature key={item.id} item={item} />
      ))}
    </div>
  );
}

export default ClassInfo;
