import React, { useRef } from "react";
import styles from "./index.module.css";
import MenuIcon from "../../assests/images/menuIcon.png";
import CloseIcon from "../../assests/images/closeIcon.png";
import MobileHeaderLogo from "../../assests/images/mobile_header_logo.png";
import useNavItems from "./useNavItems";
import useHeader from "./useHeader";

const SideHeader = ({ sideMenuRef, sectionRefs, headerRef, close }) => {
  const { handleButtonClick, selectedButton } = useNavItems(
    sectionRefs,
    headerRef
  );

  return (
    <div ref={sideMenuRef} className={styles.side_menu_container}>
      <div className={styles.close_container}>
        <img src={CloseIcon} alt="Close" onClick={() => close()} />
        <div className={styles.nav_item_container}>
          <div
            className={`${styles.nav_item_title} ${
              selectedButton === 0
                ? styles.nav_item_title_active
                : styles.nav_item_title_normal
            }`}
            onClick={() => {
              close();
              handleButtonClick(0);
            }}
          >
            Home
          </div>
          <div
            className={`${styles.nav_item_title} ${
              selectedButton === 1
                ? styles.nav_item_title_active
                : styles.nav_item_title_normal
            }`}
            onClick={() => {
              close();
              handleButtonClick(1);
            }}
          >
            What You Learn?
          </div>
          <div
            className={`${styles.nav_item_title} ${
              selectedButton === 2
                ? styles.nav_item_title_active
                : styles.nav_item_title_normal
            }`}
            onClick={() => {
              close();
              handleButtonClick(2);
            }}
          >
            Testimonial
          </div>
          <div
            className={`${styles.nav_item_title} ${
              selectedButton === 3
                ? styles.nav_item_title_active
                : styles.nav_item_title_normal
            }`}
            onClick={() => {
              close();
              handleButtonClick(3);
            }}
          >
            Contact Us
          </div>
        </div>
      </div>
    </div>
  );
};

function MobileHeader({ sectionRefs, trigger }) {
  const headerRef = useRef(null);
  useHeader(headerRef, trigger, true);
  const { handleButtonClick } = useNavItems(sectionRefs, headerRef);
  const sideMenuRef = useRef(null);
  const openSideMenu = () => {
    if (sideMenuRef?.current?.classList) {
      sideMenuRef.current.classList.add(styles.open_side_Menu_width); // Add class when trigger is out of view
    }
  };

  const closeSideMenu = () => {
    if (sideMenuRef?.current?.classList) {
      sideMenuRef.current.classList.remove(styles.open_side_Menu_width); // Add class when trigger is out of view
    }
  };
  return (
    <>
      <div
        ref={headerRef}
        className={`w-100 d-flex center-align-item container_fluid container_fuild_padding_top jstify-space-between ${styles.mobile_header_container}`}
      >
        <img src={MobileHeaderLogo} alt="headeer" />
        <div className={`d-flex center-align-item ${styles.menu_icon}`}>
          <div
            className={`d-flex center-align-item jstify-space-around ${styles.contact_us}`}
            onClick={() => handleButtonClick(3)}
          >
            Contact Us
          </div>
          <img src={MenuIcon} alt="Open menu" onClick={openSideMenu} />
        </div>
      </div>
      <SideHeader
        sideMenuRef={sideMenuRef}
        sectionRefs={sectionRefs}
        headerRef={headerRef}
        close={closeSideMenu}
      />
    </>
  );
}

export default MobileHeader;
