import React from "react";
import styles from "./index.module.css";
import HomeImage from "../../assests/images/HomeImage.png";

import checklist from "../../assests/icons/ChecklistIcon.png";
import phoneIcon from "../../assests/icons/PhoneIcon.png";
import { useMediaQuery } from "react-responsive";

const ChecklistItem = ({ name, index }) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const applyStyle =  isMobile && ((index === 1) || (index === 2) || (index === 4));
  return (
    <div className={`d-flex ${styles.home_checklist_item_margin_top}`}>
      <div className={styles.home_checklist}>
        <img src={checklist} alt="Checklist" />
      </div>
      <div className={styles.home_checklist_item} style={applyStyle ? {lineHeight:"2.1rem"} : {}}>{name}</div>
    </div>
  );
};

function Home() {
  const checklistItems = [
    "Live lectures from industry experts in UX",
    "Extensive training on practical UX methodology and techniques",
    "Interactive workshop to develop critical thinking and problem-solving skills",
    "Build UX case study based on actual project",
    "Interactive workshop to develop critical thinking and problem-solving skills",
    "Build UX case study based on actual project",
  ];
  return (
    <div className={`${styles.home_container} container_fluid`}>
      <div className={styles.home_left_section_container}>
        <div className={styles.eucg}>
          Elevate your career growth by mastering User Experience expert
        </div>
        <div className={styles.home_checklist_container}>
          {checklistItems.map((item, idex) => (
            <ChecklistItem key={item + idex} name={item} index={idex}/>
          ))}
        </div>
      </div>
      <div className={styles.home_right_section_container}>
        <div className={styles.home_group_photo}>
          <div className={styles.home_group_photo_container}>
          <img src={HomeImage} alt="Group" />
          <div
            className={`d-flex center-align-item ${styles.home_contact_info}`}
          >
            <img src={phoneIcon} alt="Phone" />
            <div className={styles.phone_call_number}>+91 8591544392</div>
          </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
