import { useEffect, useState } from "react";

function useNavItems(sectionRefs, headerRef) {
  const [selectedButton, setSelectedButton] = useState(0);
  const handleButtonClick = (index) => {
    // sectionRefs[index].current?.scrollIntoView({ behavior: "smooth" });
    const offset = headerRef.current.clientHeight; // Set the desired offset value
    // Get the position of the target div
    const targetPosition =
      sectionRefs[index].current?.getBoundingClientRect().top +
      window.pageYOffset;
    // Scroll to the target div with the offset
    window.scrollTo({
      top: targetPosition - offset, // Apply the offset
      behavior: "smooth", // Smooth scroll
    });
    setSelectedButton(index);
  };
  const handleScroll = () => {
    sectionRefs.forEach((ref, index) => {
      const sectionTop = ref.current?.getBoundingClientRect()?.top;
      if (
        sectionTop !== undefined &&
        sectionTop >= 0 &&
        sectionTop < window.innerHeight / 2
      ) {
        setSelectedButton(index);
      }
    });
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return { handleButtonClick, selectedButton };
}

export default useNavItems;
