import React from "react";
import styles from "./index.module.css";

import logo from "../../assests/images/full_logo.png";

function Logo({ className, mainClass }) {
  return (
    <div className={mainClass ? mainClass : styles.logo_container_main}>
      <img
        src={logo}
        className={`w-100 ${className ? className : styles.full_logo}`}
        alt="Logo"
      />
    </div>
  );
}

export default Logo;
